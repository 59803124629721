import RouteKeys from '../../../config/RouteKeys';
import { LocaleTag } from '../../../config/locales';

const { VIDEO_LIBRARY } = RouteKeys.BROKER_CENTRE;

enum VideoCategory {
  BROKER_PORTAL = 'brokerPortal',
  CASE_STUDIES = 'caseStudies',
  DEAL_SUBMISSION_TIPS = 'dealSubmissionTips',
  ELITE_LOYALTY = 'eliteLoyalty',
}

export interface Video {
  titleKey: string;
  path: {
    [LocaleTag.EN]: string;
    [LocaleTag.FR]: string;
  };
  videoUrl: {
    [LocaleTag.EN]: string;
    [LocaleTag.FR]: string;
  };
  thumbnailsFileName: {
    [LocaleTag.EN]: string;
    [LocaleTag.FR]: string;
  };
  descriptionKey: string;
  categoryKey: VideoCategory;
}

const videos: Video[] = [
  // Hidden because of https://jira.haventreebank.com/browse/MO-112?focusedCommentId=67281&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-67281
  // {
  //   titleKey: 'instantBankStatementVerification.title',
  //   path: {
  //     ...VIDEO_LIBRARY.INSTANT_BANK_STATEMENT_VERIFICATION_TOOL.INDEX.path,
  //   },
  //   videoUrl: {
  //     en: 'https://vimeo.com/413994606',
  //     fr: 'https://vimeo.com/413994606',
  //   },
  //   thumbnailsFileName: {
  //     en: 'instantBankVerification.jpg',
  //     fr: 'instantBankVerification.jpg',
  //   },
  //   descriptionKey: 'instantBankStatementVerification.description',
  // },
  {
    titleKey: 'gettingStartedBrokerPortal.title',
    path: {
      ...VIDEO_LIBRARY.GETTING_STARTED_BROKER_PORTAL.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/658619414',
      fr: 'https://vimeo.com/662700639',
    },
    thumbnailsFileName: {
      en: 'gettingStartedBrokerPortal-en.jpg',
      fr: 'gettingStartedBrokerPortal-fr.jpg',
    },
    descriptionKey: 'gettingStartedBrokerPortal.description',
    categoryKey: VideoCategory.BROKER_PORTAL,
  },
  {
    titleKey: 'resetPasswordBrokerPortal.title',
    path: {
      ...VIDEO_LIBRARY.RESET_PASSWORD_BROKER_PORTAL.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/644546148',
      fr: 'https://vimeo.com/654671312',
    },
    thumbnailsFileName: {
      en: 'resetPasswordBrokerPortal-en.jpg',
      fr: 'resetPasswordBrokerPortal-fr.jpg',
    },
    descriptionKey: 'resetPasswordBrokerPortal.description',
    categoryKey: VideoCategory.BROKER_PORTAL,
  },
  {
    titleKey: 'accessLoanBrokerPortal.title',
    path: {
      ...VIDEO_LIBRARY.ACCESS_LOAN_BROKER_PORTAL.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/651258913',
      fr: 'https://vimeo.com/656731776',
    },
    thumbnailsFileName: {
      en: 'accessLoanBrokerPortal-en.jpg',
      fr: 'accessLoanBrokerPortal-fr.jpg',
    },
    descriptionKey: 'accessLoanBrokerPortal.description',
    categoryKey: VideoCategory.BROKER_PORTAL,
  },
  {
    titleKey: 'updateContactBrokerPortal.title',
    path: {
      ...VIDEO_LIBRARY.UPDATE_CONTACT_BROKER_PORTAL.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/651258749',
      fr: 'https://vimeo.com/662700830',
    },
    thumbnailsFileName: {
      en: 'updateContactBrokerPortal-en.jpg',
      fr: 'updateContactBrokerPortal-fr.jpg',
    },
    descriptionKey: 'updateContactBrokerPortal.description',
    categoryKey: VideoCategory.BROKER_PORTAL,
  },
  {
    titleKey: 'loadDocumentsBrokerPortal.title',
    path: {
      ...VIDEO_LIBRARY.LOAD_DOCUMENTS.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/649539464',
      fr: 'https://vimeo.com/656736121',
    },
    thumbnailsFileName: {
      en: 'loadDocumentsBrokerPortal-en.jpg',
      fr: 'loadDocumentsBrokerPortal-fr.jpg',
    },
    descriptionKey: 'loadDocumentsBrokerPortal.description',
    categoryKey: VideoCategory.BROKER_PORTAL,
  },
  {
    titleKey: 'debtConsolidation.title',
    path: {
      ...VIDEO_LIBRARY.DEBT_CONSOLIDATION.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/415174248',
      fr: 'https://vimeo.com/443169134',
    },
    thumbnailsFileName: {
      en: 'debtConsolidation.jpg',
      fr: 'debtConsolidation-fr.jpg',
    },
    descriptionKey: 'debtConsolidation.description',
    categoryKey: VideoCategory.CASE_STUDIES,
  },
  {
    titleKey: 'secondsPlusMortgage.title',
    path: {
      ...VIDEO_LIBRARY.SECONDS_PLUS_MORTGAGE.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/530976142',
      fr: 'https://vimeo.com/530977289',
    },
    thumbnailsFileName: {
      en: 'secondsPlusMortgage.jpg',
      fr: 'secondsPlusMortgage-fr.jpg',
    },
    descriptionKey: 'secondsPlusMortgage.description',
    categoryKey: VideoCategory.CASE_STUDIES,
  },
  {
    titleKey: 'refinance.title',
    path: {
      ...VIDEO_LIBRARY.REFINANCE.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/537416528',
      fr: 'https://vimeo.com/574200564',
    },
    thumbnailsFileName: {
      en: 'refinance.png',
      fr: 'refinance-fr.png',
    },
    descriptionKey: 'refinance.description',
    categoryKey: VideoCategory.CASE_STUDIES,
  },
  {
    titleKey: 'businessForSelfAcceleratedProgram.title',
    path: {
      ...VIDEO_LIBRARY.BFS_ACCELERATED_PROGRAM.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/428094839',
      fr: 'https://vimeo.com/443163460',
    },
    thumbnailsFileName: {
      en: 'bfsAccelerated.jpg',
      fr: 'bfsAccelerated-fr.jpg',
    },
    descriptionKey: 'businessForSelfAcceleratedProgram.description',
    categoryKey: VideoCategory.CASE_STUDIES,
  },
  {
    titleKey: 'lowFICO.title',
    path: {
      ...VIDEO_LIBRARY.LOW_FICO.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/522907443',
      fr: 'https://vimeo.com/524515459',
    },
    thumbnailsFileName: {
      en: 'lowFICO.jpg',
      fr: 'lowFICO-fr.jpg',
    },
    descriptionKey: 'lowFICO.description',
    categoryKey: VideoCategory.CASE_STUDIES,
  },
  {
    titleKey: 'expediteYourDeal.title',
    path: {
      ...VIDEO_LIBRARY.EXPEDITE_YOUR_DEAL.INDEX.path,
    },
    videoUrl: {
      en: 'https://vimeo.com/415263948',
      fr: 'https://vimeo.com/443183701',
    },
    thumbnailsFileName: {
      en: 'expediteYourDeal.jpg',
      fr: 'expediteYourDeal-fr.jpg',
    },
    descriptionKey: 'expediteYourDeal.description',
    categoryKey: VideoCategory.DEAL_SUBMISSION_TIPS,
  },
  // {
  //   titleKey: 'pointsRedemption.title',
  //   path: {
  //     ...VIDEO_LIBRARY.POINTS_REDEMPTION.INDEX.path,
  //   },
  //   videoUrl: {
  //     en: 'https://vimeo.com/451632676',
  //     fr: 'https://vimeo.com/467868871',
  //   },
  //   thumbnailsFileName: {
  //     en: 'pointsRedemption.png',
  //     fr: 'pointsRedemption-fr.png',
  //   },
  //   descriptionKey: 'pointsRedemption.description',
  //   categoryKey: VideoCategory.ELITE_LOYALTY,
  // },
  // {
  //   titleKey: 'passwordReset.title',
  //   path: {
  //     ...VIDEO_LIBRARY.PASSWORD_RESET.INDEX.path,
  //   },
  //   videoUrl: {
  //     en: 'https://vimeo.com/457070205',
  //     fr: 'https://vimeo.com/465519814',
  //   },
  //   thumbnailsFileName: {
  //     en: 'passwordReset.png',
  //     fr: 'passwordReset-fr.png',
  //   },
  //   descriptionKey: 'passwordReset.description',
  //   categoryKey: VideoCategory.ELITE_LOYALTY,
  // },
];

export default videos;
